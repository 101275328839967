.ReactCollapse--collapse {
  transition: height 500ms ease-in;
}

.__react_component_tooltip {
  transition: opacity 0.5s ease-in;
  opacity: 0;
  visibility: visible;
}

.__react_component_tooltip.show {
  visibility: visible;
  opacity: 1;
}

.fade-landscape-rec {
  animation: fadeInOut 10s;
  animation-fill-mode: forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.arButton {
  position: absolute;
  z-index: 2;
  background-color: #fff !important;
  height: auto !important;
  border: 2px solid black !important;
  border-radius: 8px;
  line-height: 40px;
}

.arButton:hover {
  background-color: #555 !important;
  border: 0;
}

.canvasWrapper {
  right: 0;
  width: 'unset';
  background: linear-gradient(180deg, rgba(235, 223, 215, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

button, .personalize {
  filter: drop-shadow(0px 3px 3px #bbb);
}

.personalize {
  padding: 24px 0 24px 24px;
	margin: 30px 0 10px 0;
	border-radius: 8px;
	position: relative;
}

/* Thin scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 1px; /* 1px wide scrollbar */
  height: 1px; /* 1px height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 1px; /* Rounded corners for a modern look */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

/* Thin scrollbar for Firefox */
* {
  scrollbar-width: none; /* Makes the scrollbar invisible */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

.clickable-icons {
  position: absolute;
  z-index: 2;
  top: 0;
  left: -50px;
}

.clickable-icons > a {
  display: block;
}

.copy-icon {
  margin-top: 10px;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.tooltip {
  position: absolute;
  z-index: 2;
  top: 30px;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

@media screen and (max-width: 900px) {
  .clickable-icons {
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;
  }

  button, .personalize {
    filter: none;
  }  

  .arButton {
    background-color: transparent !important;
    border-width: 1px !important; 
  }
}
